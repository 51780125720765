.curio__header{
  font-family: Manrope;
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 2rem 4rem;
}

.gradient__text{
  background: var(--gradient-text);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.curio__icon{
  width: 15mm;
  height: 15mm;
}

.curio__tag{
  padding: 0rem 4.5rem;
}

.curio__sign{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.curio__sign p{
  color: #FFF;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  text-transform: capitalize;

  margin: 0 1rem;
  cursor: pointer;
}

.curio__sign button{
  padding: 0.5rem 1rem;
  color: #FFF;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  width: 152px;
  height: 58px;
  left: 1153px;
  top: 55px;

  background: #FF4820;
  border-radius: 5px;
}